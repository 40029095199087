import React from "react";

import Layout from "../components/Layout";
import data from "../../content/resources/solution.json"
import {Link} from "gatsby";

const Solution = () => (
    <Layout>
        <div id="main">
            <div className="services_section_banner">
                <div className="services_banner">
                    <h1>Solución</h1>
                </div>
            </div>
            <div className="inner">
                <section id="services">
                    <div className="services_section_1">
                    </div>
                    <section className="tiles">
                        {data.map((service) => {
                            return (
                                <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                    <Link to={service.path} state={{service: service}}>
                                        <h2>{service.name}</h2>
                                        <div className="content">
                                            <p>
                                                {service.description}
                                            </p>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </section>
                </section>
            </div>
        </div>
    </Layout>
);

export default Solution;
